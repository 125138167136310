// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-article-tsx": () => import("./../../../src/pages/article.tsx" /* webpackChunkName: "component---src-pages-article-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-our-community-1-tsx": () => import("./../../../src/pages/our-community-1.tsx" /* webpackChunkName: "component---src-pages-our-community-1-tsx" */),
  "component---src-pages-vacancies-1-tsx": () => import("./../../../src/pages/vacancies-1.tsx" /* webpackChunkName: "component---src-pages-vacancies-1-tsx" */),
  "component---src-pages-vacancy-1-tsx": () => import("./../../../src/pages/vacancy-1.tsx" /* webpackChunkName: "component---src-pages-vacancy-1-tsx" */),
  "component---src-pages-vacancy-[ref]-tsx": () => import("./../../../src/pages/vacancy/[ref].tsx" /* webpackChunkName: "component---src-pages-vacancy-[ref]-tsx" */),
  "component---src-pages-working-with-us-1-tsx": () => import("./../../../src/pages/working-with-us-1.tsx" /* webpackChunkName: "component---src-pages-working-with-us-1-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

