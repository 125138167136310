import React from 'react'
import Layout from './src/components/Layout'
import { AppContextProvider } from './src/context/AppContext'

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element }) => {
  return <AppContextProvider>{element}</AppContextProvider>
}
